<script setup lang="ts">
</script>

<template>
  <Header />
  <NuxtRouteAnnouncer />
  <NuxtLoadingIndicator />
  <Toaster />
  <div class="p-6">
    <NuxtPage />
  </div>
</template>

<style>
@import './assets/app.css';
</style>
